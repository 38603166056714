
<div class="card d-flex justify-content-center registeration-container box">
    <div class="registeration-content">
        <div class="card-title text text-center">
            <h2>
                سجل بياناتك الآن                
            </h2>
        </div>
        <div class="card-body">
            <div class="text-center">
                <div class="container-fluid">
                    <div class="card  border-0">
                        <div class="card-body">
                            <form [formGroup]="LandingForm" novalidate >
                                <div class="right mb-4">
    
                                    <div class="mb-3 mt-3 form-group right">
                                        <label for="FName" class="form-label input-lable">
                                            الاسم الأول*
                                        </label>
                                        <div class="input ">
                                            <input type="text" formControlName="FName" class="form-control" id ="FName">
                                        </div>
                                    </div>
    
                                    <div  *ngIf="  (LandingForm.controls['FName'].dirty || LandingForm.controls['FName'].touched)
                                                   &&LandingForm.controls['FName'].invalid" class="alert alert-danger">
    
                                            <div *ngIf="LandingForm.controls['FName'].hasError('required')">
                                                Please complete this required field.
                                            </div>
                                    </div>
    
                                    <div class="mb-3 mt-3 form-group right">
                                        <label for="LName" class="form-label right input-lable" >
                                            اسم العائلة*
                                        </label>
                                        <input type="text" formControlName="LName" class="form-control" id="LName">
                                    </div>
                                    <div   *ngIf=" (LandingForm.controls['LName'].dirty|| LandingForm.controls['LName'].touched)
                                                   &&LandingForm.controls['LName'].invalid"  class="alert alert-danger">
    
                                            <div *ngIf="LandingForm.controls['LName'].hasError('required')">
                                                Please complete this required field.
                                            </div>
                                    </div>
    
                                    <div class="mb-3 mt-3 form-group right">
                                        <label for="Email" class="form-label right input-lable" >
                                            البريد الإلكتروني*
                                        </label>
                                        <input type="email"formControlName="Email" class="form-control" id="Email">
                                    </div>
                                    <div *ngIf=" (LandingForm.controls['Email'].dirty || LandingForm.controls['Email'].touched)
                                                 && LandingForm.controls['Email'].invalid " class="alert alert-danger">
    
                                            <div *ngIf="LandingForm.controls['Email'].hasError('required')">
                                                Please complete this required field.
                                            </div>
                                    </div>
    
                                    <div class="mb-3 mt-3 form-group right">
                                        <label for="Phone" class="form-label right input-lable">
                                            رقم تليفون واتساب*
                                        </label>
                                        <input type="tel" formControlName="Phone" class="form-control" id="Phone">
                                    </div>
                                    <div *ngIf="(LandingForm.controls['Phone'].dirty || LandingForm.controls['Phone'].touched)
                                                 && LandingForm.controls['Phone'].invalid" class="alert alert-danger">
    
                                            <div *ngIf="LandingForm.controls['Phone'].hasError('required')">
                                                Please complete this required field.
                                            </div>
                                    </div>
    
                                    <div class="mb-3 mt-3 form-group right">
                                        <label for="ClinicName"  class="form-label right input-lable" >
                                            اسم
                                            العيادة*
                                        </label>
                                        <input type="text" formControlName="ClinicName" class="form-control" id="ClinicName">
                                    </div>
                                    <div *ngIf="(LandingForm.controls['ClinicName'].dirty || LandingForm.controls['ClinicName'].touched)
                                                && LandingForm.controls['ClinicName'].invalid  " class="alert alert-danger">
    
                                            <div *ngIf="LandingForm.controls['ClinicName'].hasError('required')">
                                                Please complete this required field.
                                            </div>
                                    </div>
                                    <div class="">
                                        <button type="submit" [disabled]='!LandingForm.valid' (click)="AddUserLandingPage()" class="btn mt-3 btn-success btn-lg text-right "
                                            style="width:130px; height: 50px;"> 
                                            <span class="text-center d-flex justify-content-center">
                                                ارسال</span>
                                            </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>