import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/shared/shared.service';

@Component({
  selector: 'app-visitor-layout',
  templateUrl: './visitor-layout.component.html',
  styleUrls: ['./visitor-layout.component.css']
})
export class VisitorLayoutComponent implements OnInit {
  
  constructor(  private sharedService: SharedService ) { }

  ngOnInit(): void {
    this.sharedService.loadCssToHTMlPage("assets/css/bootstrap.min.css");
    this.sharedService.removeCssFromHTMlPage("assets/css/updatedAce.css");
  }

}
