<div *ngIf="showProgress">
    <loading></loading>
</div>
<br />
<div class="row">
    <div class="col-md-4">
        <div class="well">
            <div class="row">
                <div class="col-md-12 nopadding">
                    <div class="well">
                        <div class="inner-addon left-addon">
                            <i class="glyphicon glyphicon-search"></i>
                            <input type="search" [placeholder]="'Search' | translate" class="inputSearch form-control" [(ngModel)]='userListfilterString' />
                        </div>

                    </div>
                </div>
            </div>
            <div>
                <table class="table table-responsive table-bordered">
                    <thead class="alert alert-info">
                        <tr>
                            <th class="col-md-9" style="color:#c492ef;" colspan="2">{{ 'Users' | translate}}</th>
                        </tr>
                    </thead>
                    <tr *ngFor="let usr of usersList | filterByName:userListfilterString">
                        <td class="col-md-9"><a (click)="editUser(usr.id)" class="pointer">{{selectedLang === 'ar' ?  usr.name : usr.nameTranslation}}</a></td>
                    </tr>
                </table>
            </div>
        </div>

    </div>
    <div class="col-md-8 divBorders">
        <form *ngIf="active" class="form-horizontal" name="userPermissionsForms" (ngSubmit)="onSubmit()" #userPermissionsForms="ngForm">
            <fieldset>
                <div class="form-group text-center">
                    <button class="btn btn-primary" [disabled]="!isUserSelected || !isClinicAdmin">{{ 'Save' | translate}}</button>
                    <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#resetConfirmation" [disabled]="!isUserSelected || !isClinicAdmin">{{ 'ResetPassword' | translate}}</button>
                    <button type="button" class="btn btn-primary" (click)="clear()" disabled="{{ isClinicAdmin ? '' :'disabled'}}">Clear</button>
                </div>
                <div class="form-group">
                    <h4 class="h4Deco"> {{ 'UserInfo' | translate}}</h4>
                    <label for="txtName" class="control-label col-md-2">{{ 'Name' | translate}}</label>
                    <div class="col-md-4 nopadding">
                        <input type="text" class="form-control" name="txtName" id="txtName" [(ngModel)]='model.name' disabled />
                    </div>
                    <label for="txtUserType" class="control-label col-md-2">{{ 'Type' | translate}}</label>
                    <div class="col-md-4 nopadding">
                        <input type="text" class="form-control" name="txtUserType" id="txtUserType" [(ngModel)]='model.typeName' disabled />
                    </div>
                </div>
                <div class="form-group">
                    <label for="txtEmail" class="control-label col-md-2">{{ 'Email' | translate}}</label>
                    <div class="col-md-4 nopadding">
                        <input type="email" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$" class="form-control" name="txtEmail" id="txtEmail" [(ngModel)]='model.email' />
                    </div>
                </div>
                <div class="form-group">
                    <h3 class="h3Deco">{{ 'MemberOfGroups' | translate}}</h3>
                    <hr />
                    <div class="row">
                        <div class="col-md-5 nopadding">
                            <h4 class="h4Deco">{{ 'AllGroups' | translate}}</h4>

                        </div>
                        <div class="col-md-5 nopadding col-md-offset-1">
                            <h4 class="h4Deco">{{ 'SelectedGroups' | translate}}</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="checkboxListContainer col-md-5 nopadding">
                            <div class="inner-addon left-addon">
                                <i class="glyphicon glyphicon-search"></i>
                                <input type="search" [placeholder]="'Search' | translate" class="inputSearch form-control" name="txtUserGroupSearch" id="txtUserGroupSearch" [(ngModel)]='userGroupfilterString' /> </div>

                            <div class="checkboxListScrollArea">
                                <table>
                                    <tr *ngFor="let grp of userGroupsList  | filterByName:userGroupfilterString">
                                        <td>
                                            <input type="checkbox" name="chkBoxUser" value="{{grp.id}}" [checked]="selectedGroupsIDs.indexOf(grp.id) >= 0" (change)="updateSelectedGroups(grp.id, $event)" [disabled]="isAdminGroupSelected && grp.isAdmin" />
                                            <!--{{grp.name}}-->
                                            {{selectedLang === 'ar' ? grp.name : grp.nameTranslation}}
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div class="checkboxListView col-md-5 col-md-offset-1 nopadding">
                            <table class="table table-responsive table-hover table-striped">
                                <tr *ngFor="let grp of model.securityGroups">
                                    <td>
                                        <!--{{grp.name}}-->
                                        {{selectedLang === 'ar' ? grp.name : grp.nameTranslation}}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <h4 class="h4Deco">{{ 'Permissions' | translate}}</h4>
                    <hr />
                    <div class="col-md-12" style="overflow:auto ;" >
                        <!---New Table-->
                        <p-table  [tableStyle]="{'min-width': '500px'}"
                            [value]="groupedPermissionsList" dataKey="categoryFriendlyName">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 3rem"></th>
                                    <th pSortableColumn="categoryFriendlyName">{{'Permissions' | translate}}
                                        <p-sortIcon field="categoryFriendlyName"></p-sortIcon>
                                    </th>
                                    <th><span styleClass="text-center width70">{{'FullControl' | translate}}</span>
                                    </th>
                                    <th><span styleClass="text-center width70">{{'View' | translate}} </span></th>
                                    <th><span styleClass="text-center width70">{{'Add' | translate}} </span></th>
                                    <th><span styleClass="text-center width70">{{'Edit' | translate}} </span></th>
                                    <th><span styleClass="text-center width70">{{'ActivateDeactivate' | translate}}
                                    </span></th>
                                    <th><span styleClass="text-center width70">{{'Allow' | translate}} </span></th>
                                </tr>

                            </ng-template>
                            <ng-template pTemplate="body" let-permission let-expanded="expanded">
                                <tr style="background-color: #c1cad1;">
                                    <td>
                                        <button type="button" pButton pRipple [pRowToggler]="permission" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                                    </td>
                                    <td colspan="7">
                                        {{permission.categoryFriendlyName}}
                                    </td>


                                </tr>
                            </ng-template>
                            <ng-template pTemplate="rowexpansion" let-permission>
                                <tr>
                                    <td colspan="8">
                                        <div class="p-p-3">
                                            <p-table [value]="permission.items" dataKey="id">
                                                <ng-template pTemplate="header">
                                                    <tr>
                                                        <th pSortableColumn="name">Name
                                                            <p-sortIcon field="name"></p-sortIcon>
                                                        </th>
                                                        <th><span styleClass="text-center width70">{{'FullControl' | translate}}</span>
                                                        </th>
                                                        <th><span styleClass="text-center width70">{{'View' | translate}} </span></th>
                                                        <th><span styleClass="text-center width70">{{'Add' | translate}} </span></th>
                                                        <th><span styleClass="text-center width70">{{'Edit' | translate}} </span></th>
                                                        <th><span styleClass="text-center width70">{{'ActivateDeactivate' | translate}}
                                                        </span></th>
                                                        <th><span styleClass="text-center width70">{{'Allow' | translate}} </span></th>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-object>
                                                    <tr>
                                                        <td translate> {{object.key}}</td>
                                                        <td>
                                                            <input type="checkbox" name="chkFullControl" [disabled]="!object.isFullControlAvailable" [checked]="isPermissionChecked(object.id,'fullControl', object.isFullControlAvailable)" (change)="updateSelectedPermissions(object.id,'fullControl', $event)" />
                                                        </td>
                                                        <td style="text-align: center">
                                                            <input type="checkbox" name="chkView" [disabled]="!object.isViewAvailable" [checked]="isPermissionChecked(object.id,'view', object.isViewAvailable)" (change)="updateSelectedPermissions(object.id,'view', $event)" />

                                                        </td>
                                                        <td style="text-align: center">
                                                            <input type="checkbox" name="chkAdd" [disabled]="!object.isAddAvailable" [checked]="isPermissionChecked(object.id,'add', object.isAddAvailable)" (change)="updateSelectedPermissions(object.id,'add', $event)" />

                                                        </td>
                                                        <td style="text-align: center">
                                                            <input type="checkbox" name="chkEdit" [disabled]="!object.isEditAvailable" [checked]="isPermissionChecked(object.id,'edit', object.isEditAvailable)" (change)="updateSelectedPermissions(object.id,'edit', $event)" />

                                                        </td>
                                                        <td style="text-align: center"><input type="checkbox" name="chkActivate" [disabled]="!object.isActivateAvailable" [checked]="isPermissionChecked(object.id,'activate', object.isActivateAvailable)" (change)="updateSelectedPermissions(object.id,'activate', $event)"
                                                            />
                                                        </td>
                                                        <td style="text-align: center">
                                                            <input type="checkbox" name="chkAllow" [disabled]="!object.isAllowAvailable" [checked]="isPermissionChecked(object.id,'allow', object.isAllowAvailable)" (change)="updateSelectedPermissions(object.id,'allow', $event)" />
                                                        </td>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="emptymessage">
                                                    <tr>
                                                        <td colspan="6">There are no object for this permission yet.</td>
                                                    </tr>
                                                </ng-template>
                                            </p-table>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <!--End Table-->
                    </div>
                </div>
            </fieldset>
        </form>
        <div id="resetConfirmation" class="modal fade" role="dialog">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                        <h4 class="modal-title">{{ 'ResetPasswordConfirmation' | translate}}</h4>
                    </div>
                    <div class="modal-body">
                        <p>{{ 'ResetPasswordQues' | translate}}</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-toggle="modal" data-target="#resetOwnerPasswordModal" (click)="close()">{{ 'Yes' | translate}}</button>
                        <!--<button type="button" class="btn btn-danger" (click)="resetUserPassword()" data-dismiss="modal">{{ 'Yes' | translate}}</button>-->
                        <button type="button" #btnCloseReconfirm class="btn btn-primary" data-dismiss="modal">{{ 'No' | translate}}</button>
                    </div>
                </div>

            </div>
        </div>

        <div id="resetOwnerPasswordModal" class="modal fade" role="dialog">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <reset-password [userId]="model.id" [enableMyAccountBtn]="enable"></reset-password>
                </div>
            </div>
        </div>

    </div>
</div>