import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestOptions } from '@angular/http/src/base_request_options';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ng2-webstorage';
import { remoteServerUrl } from 'src/app/app.config';
import { BaseService } from 'src/app/shared/shared/base.service';
import { VisitorAccessTokenCredentialsModel } from '../models/VisitorAccessTokenCredentialsModel';
import { VisitorAuthenticationModel } from '../models/VisitorAuthenticationModel';
import { RegisterVisitorModel } from '../models/RegisterVisitorModel';
import { AuthenticatedVisitorModel } from '../models/AuthenticatedVisitorModel';
import { ClinicModel } from '../models/ClinicModel';
import { DepartmentModel } from '../models/DepartmentModel';
import {  DivisionModel } from '../models/DivisionModel';
import { NewsModel } from '../models/NewsModel';
import { CountryModel } from '../models/CountryModel';
import { DoctorModel } from '../models/DoctorModel';
import { PatientProfileModel } from '../models/PatientProfileModel';
import { PatientAppointmentsModel } from '../models/PatientAppointmentsModel';
import { FeedbackRepliesModel } from '../models/FeedbackRepliesModel';
import { patientFeedbacksModel } from '../models/patientFeedbacksModel';
import { CreateFeedbackModel } from '../models/CreateFeedbackModel';
import { CreatePatientInsuranceModel } from '../models/CreatePatientInsuranceModel';
import { PatientInsurancesDetailsModel } from '../models/PatientInsurancesDetailsModel';
import { CancelAppointmentModel } from '../models/AppointmentModels';
import { ClinicWithReachInfo } from '../models/ClinicWithReachInfo';
import { ClinicNews } from 'src/app/classes/clinicNews.class';
import { BookAppointmentModel } from '../models/AppointmentModels';

@Injectable({
  providedIn: 'root'
})
export class MobileService extends BaseService {

  PatientApiURL = `${remoteServerUrl}Patient`;
  AccountApiURL = `${remoteServerUrl}Account`;
  ClinicApiURL = `${remoteServerUrl}Clinic`;
  AddressApiURL = `${remoteServerUrl}address`;
  DoctorApiURL = `${remoteServerUrl}Doctor`;
  appointmentApiURL = `${remoteServerUrl}appointment`;

  constructor(private http: HttpClient,
    public localStorage: LocalStorageService,
    public router: Router) {
    super(localStorage, router);
  }

  /*******************************AccountApiURL*******************************************/
  AuthenticateVisitor(AuthenticationCredentials: VisitorAuthenticationModel): any {
    return this.http.post<AuthenticatedVisitorModel>(`${this.AccountApiURL}/AuthenticatePatient`, AuthenticationCredentials, this.requestOptions)
  }
  
  /*******************************remoteServerUrl*******************************************/
  GenerateAccessToken(TokenCredentials: VisitorAccessTokenCredentialsModel): any {
    
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Headers': 'Content-Type',
        'Access-Control-Allow-Methods': '*',
        'Access-Control-Allow-Origin': '*'
      })
    };
    
    let Credentials = `grant_type=${TokenCredentials.grant_type}&username=${TokenCredentials.username}&password=${TokenCredentials.password}&clientid=${TokenCredentials.clientid}`;
    return this.http.post(`${remoteServerUrl}/token`, Credentials, this.requestOptions)
  }
  
  /*******************************appointmentApiURL*******************************************/

  cancelAppointment(appointment: CancelAppointmentModel): any {
    return this.http.post(`${this.appointmentApiURL}/cancelAppointment`, appointment, this.requestOptions)
  }

  BookAppointment(appointment: BookAppointmentModel): any {
    return this.http.post(`${this.appointmentApiURL}/saveAppointment`, appointment, this.requestOptions)
  }

  AppointmentDetailsWrapper(doctorId: number): any {
    return this.http.get<any>(`${this.appointmentApiURL}/AppointmentDetailsWrapper/${doctorId}`, this.requestOptions)
  }


  /*******************************PatientApiURL*******************************************/
  RegisterVisitor(Patient: RegisterVisitorModel): any {
    return this.http.post(`${this.PatientApiURL}/createPatientFromMobile`, Patient, this.requestOptions)
  }

  getPatientProfile(PatientId: number): any {
    return this.http.get<PatientProfileModel>(`${this.PatientApiURL}/getPatientProfile/${PatientId}`, this.requestOptions)
  }

  getPatientAppointments(PatientId: number): any {
    return this.http.get<PatientAppointmentsModel[]>(`${this.PatientApiURL}/getPatientAppointments/${PatientId}`, this.requestOptions)
  }
  
  getFeedbackReplies(feedbackId: number): any {
    return this.http.get<FeedbackRepliesModel[]>(`${this.PatientApiURL}/feedbackReplies/${feedbackId}`, this.requestOptions)
  }

  getpatientFeedbacks(PatientId: number): any {
    return this.http.get<patientFeedbacksModel[]>(`${this.PatientApiURL}/patientFeedbacks/${PatientId}`, this.requestOptions)
  }
  
  CreateFeedback(CreateFeedback: CreateFeedbackModel): any {
    debugger;
    return this.http.post<AuthenticatedVisitorModel>(`${this.PatientApiURL}/createFeedback`, CreateFeedback, this.requestOptions)
  }
  getPatientPersonalWrapper(): any {
    return this.http.get(this.PatientApiURL + "/patientPersonalWrapper", this.requestOptions)
}

updatePatientProfile(body: any): any {
  debugger;
  body = this.addModificationData(body);
  return this.http.put(this.PatientApiURL + "/updatePatientProfile", body, this.requestOptions)
}

patientInsuranceWrapperForMobile(): any {
  return this.http.get(this.PatientApiURL + "/patientInsuranceWrapperForMobile", this.requestOptions)
}

createPatientInsuranceFromMobile(Insurance:CreatePatientInsuranceModel): any {
  return this.http.post(`${this.PatientApiURL}/createPatientInsuranceFromMobile`, Insurance, this.requestOptions)
}

updatePatientInsuranceFromMobile(Insurance:CreatePatientInsuranceModel): any {
  return this.http.put(`${this.PatientApiURL}/updatePatientInsuranceFromMobile`, Insurance, this.requestOptions)
}


PatientInsurancesForMobile(PatientId:number): any {
  return this.http.get<PatientInsurancesDetailsModel[]>(`${this.PatientApiURL}/PatientInsurancesForMobile/${PatientId}`, this.requestOptions)
}

  /*******************************ClinicApiURL*******************************************/
  GetlatestNews():any{
    return this.http.get<NewsModel[]>(`${this.ClinicApiURL}/latestNews`, this.requestOptions)
  }
  
  GetallActiveClinics():any{
    return this.http.get<ClinicModel[]>(`${this.ClinicApiURL}/allActiveClinics`, this.requestOptions)
  }

  GetallClinicsWithReachInfo():any{
    return this.http.get<ClinicWithReachInfo[]>(`${this.ClinicApiURL}/allClinicsWithReachInfo`, this.requestOptions)
  }
  
  GetclinicMedicalDepartments(Id:number):any{
    return this.http.get<DepartmentModel[]>(`${this.ClinicApiURL}/clinicMedicalDepartments/${Id}`, this.requestOptions)
  }
  
  GetdepartmentDivisions(Id:number):any{
    return this.http.get<DivisionModel[]>(`${this.ClinicApiURL}/departmentDivisions/${Id}`, this.requestOptions)
  }

  ClinicNewsByIdForMobile(Id:number):any{
    return this.http.get<ClinicNews[]>(`${this.ClinicApiURL}/ClinicNewsByIdForMobile/${Id}`, this.requestOptions)
  }


  /*******************************AddressApiURL*******************************************/
  
  GetAddressWrapper():any{
    return this.http.get<CountryModel[]>(`${this.AddressApiURL}/addressWrapper`, this.requestOptions)
  }

  /*******************************DoctorApiURL*******************************************/

  findDoctorByName(DoctorName:string):any{
    return this.http.get<DoctorModel[]>(`${this.DoctorApiURL}/findDoctorByName/${DoctorName}`, this.requestOptions)
  }

  GetAllDoctorsByClinicId(Id:number):any{
    return this.http.get(`${this.DoctorApiURL}/GetAllDoctorsByClinicId?ClinicId=${Id}`, this.requestOptions)
  }
}
