export class VisitorAccessTokenCredentialsModel{
    grant_type!:string;
    username!:string; // Id Number
    password!:string; // Mobile Number
    clientid!:string;

    constructor(){
        this.clientid="Mobile";
        this.grant_type="password";
    }

}