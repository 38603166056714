<div dir="{{ 'direction' | translate }}" class="main-app" style="border:white;" id="pagecont" [ngClass]="{ 'page-container list-menu-view': userLoggedIn }"
    *ngIf="userLoggedIn ">
    <div *ngIf="userLoggedIn  " class="left-aside desktop-view" id="unique">
        <div class="aside-branding">
            <a>
                <!--<img src="assets/images/App-Logo3.png" title="Logo" class="appLogo" />-->
            </a>

            <span class="aside-pin waves-effect"><i class="fa fa-thumb-tack"></i></span>
            <span class="aside-close waves-effect pointer" onclick="closeLeftBar();"><i class="fa fa-times"></i></span>
        </div>  
    </div>
    <div style="background-color: white;" [ngClass]="{ 'pageContentStyle page-content': userLoggedIn  }">
        <nav-bar *ngIf="userLoggedIn" [userRole]="loggedInUserType"
            [userName]="loggedInUserName" [notifications]="notifications"
            [unReadNotificationsNo]="noUnReadNotifications" [profileImagePath]="profileImagePath">
        </nav-bar>
        <div
            [ngClass]="{ 'pageContainerStyle': !userLoggedIn  ,'main-container': userLoggedIn }">
            <div class="container-fluid" >
                <router-outlet></router-outlet>
            </div>
        </div>
        <br />
        <br />
        <br />
        <footer class="footer-container" *ngIf="userLoggedIn"
            style="background-color:#f2a000!important;">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12 col-sm-12" style="text-align:center ;">
                        <div class="footer-left">
                            <!-- <span>&copy; 2022 <a href="https://ConClinic.com">جميع الحقوق محفوظة  ConClinic</a></span> -->
                            {{ "footer.copyRights" | translate }}
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6">
                        <div class="footer-right">
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</div>


<div dir="{{ 'direction' | translate }}" class="main-app" *ngIf="(!userLoggedIn )">
    <layout-nav-bar *ngIf="!externaluser"></layout-nav-bar>
    <router-outlet></router-outlet>
    <layout-footer *ngIf="!externaluser"></layout-footer>
</div>