<!-----------------------------------------NavBar---------------------------------->
<section name="nav-bar" class="nav-bar background-color-White">
    <div class="d-flex justify-content-center">
        <div class="justify-content-center mt-3 mb-4">
            <img alt=""
                src="https://26965690.fs1.hubspotusercontent-eu1.net/hub/26965690/hubfs/new-02.png?width=106&name=new-02.png" />
        </div>
    </div>
</section>

<!-------------Info Section-------------->
<section class="Info px-4">
    <div class="row text-center">

        <div class="col-lg-5 col-md-12 margin-top-6rem">
            <div class="box ">
                <div class="text-center">
                    <h2 class="Info-h2  ">
                        قم بإدارة عيادتك بسهولة ودقه من أي مكان وكل وقت
                        
                    </h2>
                    <img class="Info-img margin-top-6rem " alt="" src="../../../assets/images/formImg.png" />
                </div>
            </div>
        </div>
        <div class="col-lg-1 col-sm-12 my-5"></div>

        <div class="col-lg-6 col-md-12 box ">
            <div class="">
                <h2>
                    إدارة العيادات أسهل مع كونكلنك
                </h2>
                <p class="margin-top-6rem">
                    نظام سحابي يمكنك من خلاله إدارة الحجوزات والتعامل مع السجلات الطبية للمرضى وإعداد التقارير وأيضا
                    المدفوعات المباشرة والتعامل مع شركات التأمين وغيرها من الخصائص المختلفة.
                </p>
                <div class="margin-top-6rem">
                    <a class="btn btn-primary " onclick="document.getElementById('registeration').scrollIntoView();">
                        سجل الأن
                    </a>
                </div>

            </div>
        </div>

    </div>
</section>

<!-------------Why Section-------------->
<section id="registeration" class="why px-4  margin-top-6rem">
    <div class=" row ">
        <div class="col-lg-12 text-center margin-top-6rem">
            <h1>
                لماذا كونكلنك؟
            </h1>
        </div>
        <div class="col-lg-12 row margin-top-6rem why-register-container">
            <div class="col-lg-5 col-sm-12 box ">
                <img class="why-img " alt="" src="../../../assets/images/Conclinc-Dec-D5.png" />
            </div>
            <div class="col-lg-1 col-sm-12 my-5"></div>
            <div class="col-lg-5 col-sm-12 ">
                <app-add-user class="why-add-user"></app-add-user>
            </div>


        </div>
    </div>
</section>

<!------------Features------------------->
<section class=" margin-top-6rem">

    <div class="row">

        <!-----------Card 1---------------->
        <div class="card-wrap col-lg-4 col-md-6 col-sm-12">
            <div class="card h-100">
                <img src="../../../assets/images/Conclinc-Dec-D8.png" alt="...">
                <div class="card-title">
                    <h3>
                        إدارة مخزن الأدوات الطبية
                    </h3>
                    <p>
                        نظام كونكلنك يُسهل لك إدارة مخزن المستلزمات الطبية المختلفة، حيث يتيح لك البرنامج التفريغ
                        التلقائي لأوامر الشراء وربطها بالمخزن الحالي وربط طلبات العيادات من مستلزمات،
                        والإطلاع عليها حين الطلب
                    </p>
                </div>
            </div>
        </div>

        <!-----------Card 2---------------->
        <div class="card-wrap col-lg-4 col-md-6 col-sm-12">
            <div class="card h-100">
                <img src="../../../assets/images/Conclinc-Dec-D1.png" alt="...">
                <div class="card-title">
                    <h3> ربط العيادات </h3>
                    <p>
                        يُمكنك نظام كونكلنك من إدارة كل عياداتك من مكان واحد، من خلال لوحة تحكم رئيسية تظهر بها
                        المعلومات المتعلقة بإدارة نشاطك، مثل المواعيد والحجوزات الحالية والسابقة، والمدفوعات الحالية أو
                        السابقة، بالإضافة إلى عدد الأطباء المتاحين في فترة زمنية محددة وغيرها.
                    </p>
                </div>
            </div>
        </div>

        <!-----------Card 3---------------->
        <div class="card-wrap col-lg-4 col-md-6 col-sm-12">
            <div class="card h-100">
                <img src="../../../assets/images/Conclinc-Dec-D2.png" alt="...">
                <h3>إدارة الحجوزات</h3>
                <p>
                    يساعدك نظام كونكلنك على تسجيل وجدولة مواعيد المرضى إلكترونيًا مع تحديد مدة كل جلسة، وإظهار
                    الحجوزات بشكل دوري باليوم أو الأسبوع أو الشهر مع خاصية التأكيد والإلغاء لكل حجز.
                </p>
            </div>
        </div>

        <!-----------Card 4---------------->
        <div class="card-wrap col-lg-4 col-md-6 col-sm-12">
            <div class="card h-100">
                <img src="../../../assets/images/Conclinc-Dec-D42.png" alt="...">
                <h3>قاعدة بيانات متكاملة</h3>
                <p>
                    كونكلنك نظام سحابي يتيح لك تسجيل واستخراج كل البيانات اللازمة لإدارة نشاطك من أي جهاز متصل
                    بالإنترنت، يمكنك استخراج تقارير أداء العيادة وملفات المرضى والحجوزات المختلفة. كما يتيح لك أيضًا
                    التحكم الكامل في صلاحيات المستخدمين لضمان خصوصية البيانات.
                </p>
            </div>
        </div>

        <!-----------Card 5---------------->
        <div class="card-wrap col-lg-4 col-md-6 col-sm-12">
            <div class="card h-100">
                <img src="../../../assets/images/5-01.jpg" alt="...">
                <h3>
                    التعاقدات التأمينية
                </h3>
                <p>
                    ظام كونكلنك يدمج تفاصيل المريض مع شركة التأمين مما يسهل عليك سهولة الوصول للبوليصة
                    التأمينية الخاصة بالمريض وتسجيل الفواتير المصدق عليها.
                </p>
            </div>
        </div>

        <!-----------Card 6---------------->
        <div class="card-wrap col-lg-4 col-md-6 col-sm-12">
            <div class="card h-100">
                <img src="../../../assets/images/6-01.jpg" alt="...">
                <h3>
                    ملف لكل مريض
                </h3>
                <p>
                    كونكلنك يتيح لك تسجيل واسترجاع التاريخ المرضي الكامل للمريض مع الإلتزام بخصوصية المرضى، ويشمل
                    ملف كل مريض على عدة نقاط أساسية مثل: المؤشرات الحيوية، والأشعة والتحاليل، ومواعيد الزيارات
                    السابقة، وأي كشوفات سابقة في عيادات أخرى وغيرها.
                </p>
            </div>
        </div>
    </div>
</section>


<!-----------Learn More Seaction---------->
<section class="learn-more">
        <div class="content text-center row">
            <h2 class="col-lg-12">
                اعرف أكثر عن كونكلنك
            </h2>
            <div class="card-wrap col-sm-12">
                <div class="youtube-wrapper " >
                    <div style="height:50vh ;" class="row">
                        <div class="col-lg-3 co-md-12"></div>

                        <div class="col-lg-6 co-md-12">
                            <iframe style="height:100%;width: 100%;" src="https://www.youtube.com/embed/LH7houG9kjY" title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>

                        </div>
                        <div class="col-lg-3 co-md-12"></div>

                    </div>
                </div>
            </div>
        
        </div>
</section>


<!------------Clients Section------------>
<section class="clients bg-light ">
    <div class="container">

        <div class="content">
            <app-clients-slider></app-clients-slider>
        </div>

        <div class="content">
            <div class="row col-sm-12 d-flex justify-content-center">
                <div class="wrapper text-center">
                    <a href="https://www.facebook.com/conclinic.business" target="_blank" class="icon facebook">
                        <div class="tooltip">Facebook</div>
                        <span><i class="fa fa-facebook-f"></i></span>
                    </a>
                    <a href="https://api.whatsapp.com/send/?phone=01015244493&text&type=phone_number&app_absent=0"
                        target="_blank" class="icon twitter">
                        <div class="tooltip">Whatsapp</div>
                        <span><i class="fa fa-whatsapp"></i></span>
                    </a>
                    <a href="https://www.instagram.com/conclinic.business/" target="_blank" class="icon instagram">
                        <div class="tooltip">Instagram</div>
                        <span><i class="fa fa-instagram"></i></span>
                    </a>
                    <a href="./" class="icon github" target="_blank">
                        <div class="tooltip">Website</div>
                        <span><i class="fa fa-link"></i></span>
                    </a>

                </div>
            </div>
        </div>
    </div>
</section>