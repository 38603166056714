<div *ngIf="showProgress">
    <loading></loading>
</div>
<br />
<div class="row ">
    <div class="col-md-4">

        <div class="row well">

            <div class="row well">

                <div class="inner-addon left-addon">
                    <i class="glyphicon glyphicon-search"></i>
                    <input type="text" class="inputSearch form-control" [placeholder]="'Search' | translate" [(ngModel)]='groupfilterString' />
                </div>

            </div>
            <div >
                <table class="table table-responsive table-bobjected">
                    <thead class="alert alert-info">
                        <tr>
                            <th class="col-md-9" style="color:#c492ef;" colspan="2">{{ 'Groups' | translate}}</th>
                        </tr>
                    </thead>
                    <tr *ngFor="let grp of userGroupsList | filterByName:groupfilterString" class="{{(grp.id == selectedGroupId) ? 'success' : ''}}">
                        <td class="col-md-9"><a (click)="editUserGroup(grp.id)" class="pointer">{{selectedLang === 'ar'
                                ? grp.name : grp.nameTranslation}}</a></td>
                        <td class="col-md-3">
                            <a data-toggle="modal" data-target="#deleteGroupConfirmation" class="pointer" (click)="selectGroupToDelete(grp.id)">
                                <i *ngIf="!grp.isAdmin" class="glyphicon glyphicon-remove iconAd"></i>
                            </a>

                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <div class="col-md-8 divBobjects">
        <form *ngIf="active" class="form-horizontal" name="userGroupsForm" (ngSubmit)="onSubmit()" #userGroupsForm="ngForm">
            <fieldset>
                <div class="form-group ">
                    <button class="btn btn-primary" [disabled]="!userGroupsForm.form.valid || !isClinicAdmin">{{ 'Save'
                        | translate}}</button>
                    <button type="button" class="btn btn-primary" (click)="clear()" disabled="{{ isClinicAdmin ? '' :'disabled'}}">{{ 'New' | translate}}</button>
                </div>
                <div class="form-group">
                    <h3 class="h3Deco"></h3>
                    <hr />

                    <label for="txtNameTranslation" class="control-label col-md-3">{{ 'NameArabic' | translate}}</label>
                    <div class="col-md-3 nopadding">
                        <input type="text" class="form-control" name="txtGroupName" id="txtGroupName" [(ngModel)]='model.name' #txtGroupName="ngModel" required>
                        <div [hidden]="txtGroupName.valid || txtGroupName.pristine" class="alert alert-danger">
                            {{ 'RequiredField' | translate}}
                        </div>
                    </div>
                    <label for="txtGroupNameEnglish" class="control-label col-md-3"> {{ 'NameEnglish' |
                        translate}}</label>
                    <div class="col-md-3 nopadding">
                        <input type="text" class="form-control" name="txtGroupNameEnglish" id="txtGroupNameEnglish" [(ngModel)]='model.nameTranslation' #txtGroupNameEnglish="ngModel" required>
                        <div [hidden]="txtGroupNameEnglish.valid || txtGroupNameEnglish.pristine" class="alert alert-danger">
                            {{ 'RequiredField' | translate}}
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="chkIsActive" class="control-label col-md-2">{{ 'Active' | translate}}
                    </label>
                    <div class="col-md-4 nopadding">
                        <label>
                            <input id="chkIsActive" name="chkIsActive" class="ace ace-switch ace-switch-6"
                                type="checkbox" [(ngModel)]="model.isActive" #chkIsActive="ngModel" />
                            <span class="lbl"></span>
                        </label>
                    </div>
                </div>
                <div class="form-group">
                    <label for="txtLastModifiedBy" class="control-label col-md-3">{{ 'LastModifierUser' |
                        translate}}</label>
                    <div class="col-md-3 nopadding">
                        <input type="text" class="form-control" name="txtLastModifiedBy" id="txtLastModifiedBy" disabled>
                    </div>
                    <label for="txtLastModified" class="control-label col-md-3">{{ 'LastModificationDate' |
                        translate}}</label>
                    <div class="col-md-3 nopadding">
                        <input type="text" class="form-control" name="txtLastModified" id="txtLastModified" [(ngModel)]='model.lastModificationDateString' disabled>
                    </div>
                </div>
                <div class="form-group">
                    <h3 class="h3Deco">{{ 'Members' | translate}}</h3>
                    <hr />
                    <div class="row">
                        <div class="col-md-5 nopadding">
                            <h4 class="h4Deco">{{ 'AllUsers' | translate}}</h4>

                        </div>
                        <div class="col-md-5 nopadding col-md-offset-1">
                            <h4 class="h4Deco">{{ 'SelectedUsers' | translate}}</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="checkboxListContainer col-md-5 nopadding">

                            <div class="inner-addon left-addon">
                                <i class="glyphicon glyphicon-search"></i>
                                <input type="search" [placeholder]="'Search' | translate" class="inputSearch form-control" name="txtUserSearch" id="txtUserSearch" [(ngModel)]='userfilterString' />
                            </div>
                            <div class="checkboxListScrollArea">
                                <table>
                                    <tr *ngFor="let usr of usersList  | filterByName:userfilterString">
                                        <td>
                                            <input type="checkbox" name="chkBoxUser" value="{{usr.id}}" [checked]="selectedGroupUsersIDs.indexOf(usr.id) >= 0" (change)="updateSelectedUsers(usr.id, $event)" [disabled]="isAdminGroupSelected && usr.isAdmin" />
                                            <!--{{usr.name}}-->
                                            {{selectedLang === 'ar' ? usr.name : usr.nameTranslation}}
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div class="checkboxListView col-md-5 col-md-offset-1 nopadding">
                            <table class="table table-responsive table-hover table-striped">
                                <tr *ngFor="let usr of model.securityUsers">
                                    <td>
                                        <!--{{usr.name}}-->
                                        {{selectedLang === 'ar' ? usr.name : usr.nameTranslation}}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <h4 class="h4Deco">{{ 'Permissions' | translate}}</h4>
                    <hr />
                    <div class="col-md-12" style="overflow:auto ;" >

                        <!---New Table-->
                        <p-table style="display: inline-block ;min-width:500px;"
                        [value]="groupedPermissionsList" dataKey="categoryFriendlyName">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 3rem"></th>
                                    <th pSortableColumn="categoryFriendlyName">{{'Permissions' | translate}}
                                        <p-sortIcon field="categoryFriendlyName"></p-sortIcon>
                                    </th>
                                    <th><span styleClass="text-center width70">{{'FullControl' | translate}}</span>
                                    </th>
                                    <th><span styleClass="text-center width70">{{'View' | translate}} </span></th>
                                    <th><span styleClass="text-center width70">{{'Add' | translate}} </span></th>
                                    <th><span styleClass="text-center width70">{{'Edit' | translate}} </span></th>
                                    <th><span styleClass="text-center width70">{{'ActivateDeactivate' | translate}}
                                        </span></th>
                                    <th><span styleClass="text-center width70">{{'Allow' | translate}} </span></th>
                                </tr>

                            </ng-template>
                            <ng-template pTemplate="body" let-permission let-expanded="expanded">
                                <tr style="background-color: #c1cad1;">
                                    <td>
                                        <button type="button" pButton pRipple [pRowToggler]="permission" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                                    </td>
                                    <td colspan="7">
                                        {{permission.categoryFriendlyName}}
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="rowexpansion" let-permission>
                                <tr>
                                    <td colspan="8">
                                        <div class="p-p-3">
                                            <p-table [value]="permission.items" dataKey="id">
                                                <ng-template pTemplate="header">
                                                    <tr>
                                                        <th pSortableColumn="name">Name
                                                            <p-sortIcon field="name"></p-sortIcon>
                                                        </th>
                                                        <th><span styleClass="text-center width70">{{'FullControl' | translate}}</span>
                                                        </th>
                                                        <th><span styleClass="text-center width70">{{'View' | translate}} </span></th>
                                                        <th><span styleClass="text-center width70">{{'Add' | translate}} </span></th>
                                                        <th><span styleClass="text-center width70">{{'Edit' | translate}} </span></th>
                                                        <th><span styleClass="text-center width70">{{'ActivateDeactivate' | translate}}
                                                            </span></th>
                                                        <th><span styleClass="text-center width70">{{'Allow' | translate}} </span></th>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-object>
                                                    <tr>
                                                        <td translate> {{object.key}}</td>
                                                        <td>
                                                            <input type="checkbox" name="chkFullControl" [disabled]="!object.isFullControlAvailable" [checked]="isPermissionChecked(object.id,'fullControl', object.isFullControlAvailable)" (change)="updateSelectedPermissions(object.id,'fullControl', $event)" />
                                                        </td>
                                                        <td style="text-align: center">
                                                            <input type="checkbox" name="chkView" [disabled]="!object.isViewAvailable" [checked]="isPermissionChecked(object.id,'view', object.isViewAvailable)" (change)="updateSelectedPermissions(object.id,'view', $event)" />

                                                        </td>
                                                        <td style="text-align: center">
                                                            <input type="checkbox" name="chkAdd" [disabled]="!object.isAddAvailable" [checked]="isPermissionChecked(object.id,'add', object.isAddAvailable)" (change)="updateSelectedPermissions(object.id,'add', $event)" />

                                                        </td>
                                                        <td style="text-align: center">
                                                            <input type="checkbox" name="chkEdit" [disabled]="!object.isEditAvailable" [checked]="isPermissionChecked(object.id,'edit', object.isEditAvailable)" (change)="updateSelectedPermissions(object.id,'edit', $event)" />

                                                        </td>
                                                        <td style="text-align: center"><input type="checkbox" name="chkActivate" [disabled]="!object.isActivateAvailable" [checked]="isPermissionChecked(object.id,'activate', object.isActivateAvailable)" (change)="updateSelectedPermissions(object.id,'activate', $event)"
                                                            />
                                                        </td>
                                                        <td style="text-align: center">
                                                            <input type="checkbox" name="chkAllow" [disabled]="!object.isAllowAvailable" [checked]="isPermissionChecked(object.id,'allow', object.isAllowAvailable)" (change)="updateSelectedPermissions(object.id,'allow', $event)" />
                                                        </td>
                                                    </tr>


                                                </ng-template>
                                                <ng-template pTemplate="emptymessage">
                                                    <tr>
                                                        <td colspan="6">There are no object for this permission yet.</td>
                                                    </tr>
                                                </ng-template>
                                            </p-table>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <!--End Table-->
                    </div>
                </div>

            </fieldset>
        </form>
        <!-- Modal -->
        <div id="deleteGroupConfirmation" class="modal fade" role="dialog">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                        <h4 class="modal-title">{{ 'DeleteConfirmation' | translate}}</h4>
                    </div>
                    <div class="modal-body">
                        <p>{{ 'AreYouSureDelete' | translate}}</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" (click)="deleteSelectedGroup()" data-dismiss="modal">{{
                        'Yes' | translate}}</button>
                        <button type="button" class="btn btn-primary" data-dismiss="modal">{{ 'No' |
                        translate}}</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>