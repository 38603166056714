import { Component, Input, OnInit } from '@angular/core';
import { AccountService } from 'src/app/security/shared/account.service';
import { SharedService } from 'src/app/shared/shared/shared.service';

@Component({
  selector: 'app-doctor-layout',
  templateUrl: './doctor-layout.component.html',
  styleUrls: ['./doctor-layout.component.css']
})
export class DoctorLayoutComponent implements OnInit {

  @Input() notifications: any[] = [];
  @Input() externaluser: boolean = false;
  @Input() noUnReadNotifications: string = '';
  @Input() userLoggedIn: boolean = false;
  @Input() loggedInUserType: string = '';
  @Input() loggedInUserName: string = '';
  @Input() profileImagePath: string = '';

  constructor(       
     public accountService: AccountService,
     private sharedService: SharedService,

    ) {

     }

  ngOnInit(): void {

    let loggedUserId = localStorage.getItem("ng2-webstorage|userid");
        if (loggedUserId) {
            this.sharedService.removeCssFromHTMlPage("assets/css/bootstrap.min.css");
            this.sharedService.loadCssToHTMlPage("assets/css/updatedAce.css");
        }
        else {
            this.sharedService.loadCssToHTMlPage("assets/css/bootstrap.min.css");
            this.sharedService.removeCssFromHTMlPage("assets/css/updatedAce.css");

        }

      
  }

}
