import { Component, OnInit, Input, AfterContentChecked, OnChanges, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { SharedService } from '../shared/shared.service';
import * as L from 'leaflet';

@Component({

    selector: 'address',
    templateUrl: 'address.component.html'
})

export class AddressComponent implements OnInit, AfterContentChecked {
    @Input() reachInfo: any = {};
    countries: any[] = [];
    filteredCities: any[] = [];
    filteredRegions: any[] = [];
    filteredLocations: any[] = [];
    showProgress = false;

    private map:any;
    marker: any = {};
    markers: any[] = [];

    constructor(private sharedService: SharedService,
        public toastr: ToastrService
    ) { }





    ngOnInit(): void {

        //this.initializeDefaultMap();
       

        let vm = this;
        vm.showProgress = true;

        //get address wrapper
     //   debugger;
        this.sharedService.getAddressWrapper()
            .subscribe(
                function (response: any) {
                    vm.countries = response.countries;
                    //Initialize country
                    if(vm.reachInfo.countryId == undefined || vm.reachInfo.countryId == null || vm.reachInfo.countryId == 0)
                        vm.reachInfo.countryId=vm.countries[0].id;
                    vm.fillPageLists();

                },
                function (error: any) {
                    vm.toastr.error(error.error, '');
                    vm.showProgress = false;
                },
                function () {
                    vm.showProgress = false;
                });
              //  this.initializeMap();
                this.initMap()
    }

        ngOnChanges(changes: SimpleChanges) {
            
              this.displayMarker();
             this.fillPageLists();
        
        }
    

    fillPageLists() {
        debugger
        let vm = this;
        if (!(this.reachInfo == null || this.reachInfo == undefined || this.reachInfo == '') ){
           debugger
            if (vm.reachInfo.countryId != undefined && vm.reachInfo.countryId > 0) {
              debugger
                vm.fillRegions(vm.reachInfo.countryId);
            }

           /* if (vm.reachInfo.regionId != undefined && vm.reachInfo.regionId > 0) {
                vm.fillCities(vm.reachInfo.regionId);
            }*/

           /* if (vm.reachInfo.cityId != undefined && vm.reachInfo.cityId > 0) {
                vm.fillLocations(vm.reachInfo.cityId);
            }*/
        }
    }

    ngAfterContentChecked(): void {


    }

    fillRegions(id: string) {
        var country = this.countries.find(i => i.id == id);

        this.filteredRegions = [];
        this.filteredCities = [];
        this.filteredLocations = [];

        if (country != null && country.regions != null && country.regions != undefined){
            this.filteredRegions = country.regions;
            if(this.reachInfo.regionId == undefined || this.reachInfo.regionId == null || this.reachInfo.regionId == 0)
                this.reachInfo.regionId=country.regions[0].id

            this.fillCities(this.reachInfo.regionId);

        }

        if (this.filteredRegions != null && this.filteredRegions != undefined && this.filteredRegions.length > 0) {
            this.filteredRegions.sort(function (a, b) {
                return a.name > b.name ? 1 : -1;
            });
        }
    }

    fillCities(id: string) {
        //debugger
        var region = this.filteredRegions.find(i => i.id == id);

        this.filteredCities = [];
        this.filteredLocations = [];

        if (region != null && region.cities != null && region.cities != undefined){
            this.filteredCities = region.cities;

            if (this.reachInfo.cityId == 0 || this.reachInfo.cityId == null || this.reachInfo.cityId == undefined)
                this.reachInfo.cityId =this.filteredCities[0].id;

                this.fillLocations(this.reachInfo.cityId);
        }

    }

    fillLocations(id: string) {
        var city = this.filteredCities.find(i => i.id == id);

        this.filteredLocations = [];

        if (city != null && city.locations != null && city.locations != undefined){
            this.filteredLocations = city.locations;
            if (this.reachInfo.locationId == 0 || this.reachInfo.locationId == null || this.reachInfo.locationId == undefined)
                 this.reachInfo.locationId=city.locations[0].id;
        }
    }

    displayMarker() {

       // debugger;
        if (this.reachInfo && this.reachInfo.lat != null && this.reachInfo.lat != undefined && this.reachInfo.lat != '') {

            this.map.setZoom(this.reachInfo.zoom);

            this.addMarkeronMap(Number(this.reachInfo.lat), Number(this.reachInfo.lng));

            this.map.setCenter(this.marker.getPosition());
        }
    }


    private initMap(): void {

        if (this.map != undefined&&this.map !=null) {
            this.map.off();
            this.map.remove();
        }

        let vm = this;
        let markerPoint:L.LatLngExpression=[30.033333 , 31.233334] ;
        let mapZoom;

        if (this.reachInfo.lat != null && this.reachInfo.lat != undefined && this.reachInfo.lat != '') {
            markerPoint = [Number(this.reachInfo.lat), Number(this.reachInfo.lng) ];
            mapZoom = this.reachInfo.zoom;
        }
        else // default
        {
            markerPoint = [30.033333 , 31.233334] ;
            mapZoom = 5;
        }

        let divMap: any = document.getElementById('map');
        this.map = L.map(divMap, {
          center: markerPoint,
          zoom: mapZoom
        }).addEventListener('click', function (event: any) {
            vm.addMarkeronMap(event.latlng.lat, event.latlng.lng);
        }).addEventListener('zoom_changed', function (event: any) {
         //   debugger;
            vm.reachInfo.zoom = vm.map.zoom;
        });
    
        const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          maxZoom: 18,
          minZoom: 5,
          attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        });
    
        tiles.addTo(this.map);
    
     
      }

      //TODO: Search For  animation /*animation: google.maps.Animation.DROP,;*/
    addMarkeronMap(lat: number, lng: number) {

        this.removeMarker();

       let markerPoint: L.LatLngExpression=[lat, lng];
       
       this.marker= L.marker(markerPoint,{draggable:true})
       this.marker.addTo(this.map);


        this.markers.push(this.marker);

        let vm = this;
        this.marker.addEventListener('dragend', function () {
            vm.updateModelWithNewLocation(vm.marker._latlng.lat, vm.marker._latlng.lng);
        });

        // update model with new location
        this.reachInfo.zoom = this.map._zoom;
        this.updateModelWithNewLocation(lat, lng);
        
    }

    updateModelWithNewLocation(lat: any, lng: any) {
        // update model with new location
        this.reachInfo.lat = lat;
        this.reachInfo.lng = lng;
    }

    removeMarker() {
        for (var i = 0; i < this.markers.length; i++) {
         //   debugger;
            this.markers[i].removeFrom(this.map)
        }

        this.markers = [];

        this.reachInfo.lat = '';
        this.reachInfo.lng = '';
    }

    refreshingMap() {
        this.initMap();
        this.displayMarker();
    }

    toggleBounce() {
       // debugger;
        if (this.marker.getAnimation() !== null) {
            this.marker.setAnimation(null);
        } else {
            this.marker.setAnimation(google.maps.Animation.BOUNCE);
            
        }
    }
    

}