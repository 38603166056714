export class ReachInfoModel{
    mobile!:string;
    $id!:string;
    countryId!:number;
    regionId!:number;
    cityId!:number;
    locationId!:number;
    countryName!:string;
    regionName!:string;
    cityName!:string;
    locationName!:string;
    countryNameTranslation!:string;
    regionNameTranslation!:string;
    cityNameTranslation!:string;
    street: any;
    postalcode: any;
    buildingNo: any;
    flatNo: any;
    floor: any;
    lat: any;
    lng: any;
    zoom: any;
    fullAddress!:string;
    fullAddressTranslation!:string;
    phone!:string;
    countryCode!:string;
    fax!:string;
    email!:string;
    homePhone!:string;
    workPhone!:string;
    id!:number;
    name!:string;
    nameTranslation!:string;
    description!:string;
    descriptionTranslation!:string;
    isActive!:boolean;
    lastModificationDateString!:string;
    lastModificationDate!:Date;
    lastModifierID!:number;
    lastModifierName!:string;
    
    
    
}