<div class="container client-slider-container "  dir="ltr">
  <div class=" nav-bar text-center client-slider-header">
    <h2> {{"clients_slider.head"| translate}} </h2>
  </div>

  <owl-carousel-o [options]="customOptions" *ngIf="AllImagesPaths">

    <ng-template carouselSlide *ngFor="let image of AllImagesPaths" class="client-slider">
      <img [src]="image" class="border-radius client-slider-img">
    </ng-template>  
 
  </owl-carousel-o>
</div>

