<div dir="{{ 'direction' | translate }}" class="main-app page-container list-menu-view" style="border:white;"
    id="pagecont">
    <div class="left-aside desktop-view" id="unique">
        <div class="aside-branding">
            <a>
                <!--<img src="assets/images/App-Logo3.png" title="Logo" class="appLogo" />-->
            </a>

            <span class="aside-pin waves-effect"><i class="fa fa-thumb-tack"></i></span>
            <span class="aside-close waves-effect pointer" onclick="closeLeftBar();"><i class="fa fa-times"></i></span>
        </div>
    </div>
    <div style="background-color: white;" class="pageContentStyle page-content">
        <nav-bar>
        </nav-bar>
        <div class="main-container">
            <div class="container-fluid">
                <router-outlet></router-outlet>
            </div>
        </div>
        <br />
        <br />
        <br />
        <footer class="footer-container" style="background-color:#f2a000!important;">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12 col-sm-12" style="text-align:center ;">
                        <div class="footer-left">
                            {{ "footer.copyRights" | translate }}
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6">
                        <div class="footer-right">
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</div>