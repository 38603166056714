import { Component, OnInit } from '@angular/core';
import { data } from 'jquery';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ClientSliderService } from '../../services/clients-slider/client-slider.service';

@Component({
  selector: 'app-clients-slider',
  templateUrl: './clients-slider.component.html',
  styleUrls: ['./clients-slider.component.scss']
})
export class ClientsSliderComponent implements OnInit {

  customOptions: OwlOptions = {
    center:true,
    stagePadding: 80,
    margin:50,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    autoplay:true,
    rewind:true,
    navSpeed: 700,
    smartSpeed:450,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      480: {
        items: 2
      },
      768: {
        items: 3
      },
      1024: {
        items: 4
      }
    },
    nav: false
  }
  
  AllImagesPaths: string[]=[];

  constructor(private clientSliderService:ClientSliderService) { }

  ngOnInit(): void {
    this.LoadImagesPaths();  
  }


  LoadImagesPaths(){
    this.clientSliderService.GetClientsImages().subscribe(data=>{
      this.AllImagesPaths=data;
    });
  }

}
