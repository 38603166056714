import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'ng2-webstorage';
import { ToastrService } from 'ngx-toastr';
import { AuthenticatedVisitorModel } from 'src/app/mobileScreens/shared/models/AuthenticatedVisitorModel';
import { ReachInfoModel } from 'src/app/mobileScreens/shared/models/ReachInfoModel';
import { VisitorAccessTokenCredentialsModel } from 'src/app/mobileScreens/shared/models/VisitorAccessTokenCredentialsModel';
import { VisitorAuthenticationModel } from 'src/app/mobileScreens/shared/models/VisitorAuthenticationModel';
import { MobileService } from 'src/app/mobileScreens/shared/services/mobile.service';
import { SharedService } from 'src/app/shared/shared/shared.service';
import { WindowService } from 'src/app/shared/window/window.service';
import { remoteServerUrl } from '../../../../app.config';
import { AccountService } from '../../../../security/shared/account.service';
import { UserTypeEnum } from '../../../../security/shared/user-type.enum';
import * as firebase from 'firebase';
import { registerLocaleData } from '@angular/common';

const firebaseConfig = {
  apiKey: 'AIzaSyDl8BjG6aUeS3EhEbGooSECQOjcFEE-XJY',
  authDomain: 'conclinicnotifications.firebaseapp.com',
  projectId: 'conclinicnotifications',
  storageBucket: 'conclinicnotifications.appspot.com',
  messagingSenderId: '380685559461',
  appId: '1:380685559461:web:abb8ddc12c8d0687168f4c',
  measurementId: 'G-X3K66YH68X',
};

@Component({
  selector: 'visitor',
  templateUrl: './visitor.component.html',
  styleUrls: ['./visitor.component.scss'],
})
export class VisitorComponent implements OnInit {
  authenticatedUser!: AuthenticatedVisitorModel;
  windowRef: any;
  visitorForm: FormGroup;
  model: VisitorAuthenticationModel = new VisitorAuthenticationModel();
  active = true;
  showProgress = false;
  serverUrl: string = remoteServerUrl.replace('api/', '');

  constructor(
    private fb: FormBuilder,
    private _sanitizer: DomSanitizer,
    public accountService: AccountService,
    public mobileService: MobileService,
    private toastr: ToastrService,
    private localStorage: LocalStorageService,
    private router: Router,
    private sharedService: SharedService,
    private win: WindowService,
    public translate: TranslateService
  ) {
    //if IsVisitor flag still exists then remove it
   /* if (localStorage.retrieve('IsVisitor') == true) {
      localStorage.clear('IsVisitor');
      window.location.href = window.location.href;
    }*/

    this.visitorForm = this.fb.group({
      IdNumber: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9]*$'),
        ]),
      ],
      Mobile: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9]*$'),
        ]),
      ],
    });
  }

  ngOnInit(): void {}

  login(): void {
    let thisComp = this;
    this.model.ReachInfo = new ReachInfoModel();
    this.model.IDNumber = this.visitorForm.controls['IdNumber'].value;
    this.model.ReachInfo.mobile = this.visitorForm.controls['Mobile'].value;

    this.showProgress = true;
    this.mobileService.AuthenticateVisitor(this.model).subscribe(
      function (authenticatedUser: AuthenticatedVisitorModel) {
        debugger;
        if (authenticatedUser != null) {
          thisComp.authenticatedUser = authenticatedUser;
          thisComp.OpenVeyficationModal();
        }
      },
      function (error: any) {
        if (error.error != undefined) thisComp.toastr.error(error.error, '');

        thisComp.showProgress = false;
      },
      function () {
        thisComp.showProgress = false;
      }
    );
  }

  generateAccessToken(authenticatedUser: AuthenticatedVisitorModel): void {
    let thisComponent = this;
    /***************************** AccessTokenCredentials ********************* */
    let AccessTokenCredentials: VisitorAccessTokenCredentialsModel =
      new VisitorAccessTokenCredentialsModel();
    AccessTokenCredentials.password = this.model.ReachInfo.mobile;
    AccessTokenCredentials.username = this.model.IDNumber;
    this.showProgress = true;

    this.mobileService.GenerateAccessToken(AccessTokenCredentials).subscribe(
      function (response: any) {
        /************************authenticatedUser Data*********************************/
        thisComponent.localStorage.store(
          'UserName',
          authenticatedUser.firstName
        );
        thisComponent.localStorage.store(
          'FullUserName',
          authenticatedUser.name
        );
        thisComponent.localStorage.store('UserID', authenticatedUser.id);
        thisComponent.localStorage.store(
          'ClinicID',
          authenticatedUser.clinicId
        );
        thisComponent.localStorage.store(
          'AuthenticatedUserId',
          authenticatedUser.id
        );
        thisComponent.localStorage.store(
          'ProfileImagePath',
          thisComponent.ConvertBase64ToImagePath(authenticatedUser.imageData)
        );

        /****************************** Token Data ***********************************************/
        thisComponent.localStorage.store('AccessToken', response.access_token);

        let TokenExpireTime = new Date();
        TokenExpireTime.setSeconds(
          TokenExpireTime.getSeconds() + parseInt(response.expires_in)
        );
        thisComponent.localStorage.store('expires_in', TokenExpireTime);

        /***********************************Additional Info***************************************************/
        thisComponent.localStorage.store('IsDoctor', false);
        thisComponent.localStorage.store('IsReceptionist', false);
        thisComponent.localStorage.store('IsVisitor', true);

        thisComponent.accountService.changeLoginStatus(
          true,
          authenticatedUser.firstName,
          '',
          true,
          ''
        );

        /**********************Redirect to**********************/
        thisComponent.router.navigateByUrl('/mobile/homeSearch').then(() => {
          window.location.reload();
        });

        // location.reload();
        thisComponent.removeNewDesignFiles();
        thisComponent.translate.currentLang == 'ar'
          ? thisComponent.adjustUIForArabic()
          : thisComponent.adjustUIForEnglish();
        [];
      },
      function (error: any) {
        thisComponent.toastr.error(error.error, '');
        thisComponent.showProgress = false;
      },
      function () {
        thisComponent.showProgress = false;
      }
    );
  }

  SignUp() {
    this.localStorage.store('IsVisitor', true);
    this.router.navigateByUrl('/mobile/patientRegister').then(() => {
      window.location.reload();
    });
  }
  /**********************************FireBase*********************************************************/
  OpenVeyficationModal() {
    this.InitializeRecaptcha();
    /* Open Verification Modal*/
    let VerificationModalBTN: HTMLElement = document.getElementById(
      'Verify-Mobile'
    ) as HTMLElement;
    if (VerificationModalBTN) {
      VerificationModalBTN.click();
    }
  }

  InitializeRecaptcha() {
    this.windowRef = this.win.windowRef;
    firebase.initializeApp(firebaseConfig);
    this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'recaptcha-container'
    );
    this.windowRef.recaptchaVerifier.render();

    this.windowRef.recaptchaVerifier.verify().then((Res: any) => {
      debugger;
      this.sendLoginCode();
    });
  }

  sendLoginCode() {
    let thisComp = this;
    this.showProgress = true;
    const appVerifier = this.windowRef.recaptchaVerifier;
    let phone = `+20${this.visitorForm.controls['Mobile'].value}`;

    firebase
      .auth()
      .signInWithPhoneNumber(phone, appVerifier)
      .then((result) => {
        this.windowRef.confirmationResult = result;
        this.showProgress = false;
      })
      .catch((error) => {
        thisComp.toastr.error(error, '');
        this.showProgress = false;
      });
  }

  verifyLoginCode(verificationCode: string) {
    let thisComp = this;
    this.showProgress = true;
    this.windowRef.confirmationResult
      .confirm(verificationCode)
      .then((result: any) => {
        thisComp.showProgress = false;
        if (thisComp.authenticatedUser != null) {
          thisComp.generateAccessToken(thisComp.authenticatedUser);
        }
      })
      .catch((error: any) => {
        thisComp.showProgress = false;
        thisComp.toastr.error('Incorrect code entered?');
        console.log(error);
      });
  }

  /**********************************FireBase*********************************************************/

  /*******************************Lang Dir Files********************************************************/
  ContinueWithoutAnAccount() {
    this.localStorage.store('IsVisitor', true);

    this.router.navigateByUrl('/mobile/homeSearch').then(() => {
      window.location.reload();
    });
  }

  removeNewDesignFiles() {
    this.sharedService.removeCssFromHTMlPage('assets/css/bootstrap.min.css');
    this.sharedService.loadCssToHTMlPage('assets/css/updatedAce.css');
  }

  adjustUIForArabic() {
    this.localStorage.store('selectedLanguage', 'ar');
    this.sharedService.removeCssFromHTMlPage('assets/css/styles-ltr.css');
    this.sharedService.removeCssFromHTMlPage(
      'assets/css/bootstrap-ltr.min.css'
    );
    this.sharedService.removeCssFromHTMlPage('styles-ltr.css');
    this.sharedService.removeCssFromHTMlPage('bootstrap-ltr.min.css');
    this.sharedService.loadCssToHTMlPage('assets/css/bootstrap-rtl.min.css');
    this.sharedService.loadCssToHTMlPage('assets/css/styles-rtl.css');
    window.document.body.style.direction = 'rtl';
  }

  adjustUIForEnglish() {
    this.localStorage.store('selectedLanguage', 'en');
    this.sharedService.removeCssFromHTMlPage('styles-rtl.css');
    this.sharedService.removeCssFromHTMlPage('bootstrap-rtl.min.css');
    this.sharedService.loadCssToHTMlPage('assets/css/styles-ltr.css');
    window.document.body.style.direction = 'ltr';
  }
  /*******************************Lang Dir Files********************************************************/

  /************************General FNs ********************************** */
  ConvertBase64ToImagePath(base64string: any) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(
      'data:image/jpg;base64,' + base64string
    );
  }
}
