<app-visitor-layout *ngIf="IsVisitor; else Doctor">
</app-visitor-layout>

<ng-template #Doctor>
    <app-doctor-layout 
                        [userLoggedIn]="accountService.userLoggedIn"
                        [loggedInUserType]="accountService.loggedInUserType"
                        [loggedInUserName]="accountService.loggedInUserName"
                        [profileImagePath]="accountService.profileImagePath"
                        [noUnReadNotifications]="noUnReadNotifications" 
                        [notifications]="notifications"
                        [externaluser]="externaluser"
                        
                    >
    </app-doctor-layout>
</ng-template>