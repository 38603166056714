﻿//Live
//export const remoteServerUrl: string = "https://conclinic.com:1443/api/";
//export const reportUrl: string = "http://report.conclinic.com/";

//Stage
   export const remoteServerUrl: string = "https://backstage.conclinic.com/api/";
  export const reportUrl: string = "https://reportstage.conclinic.com/"; //reportstage

//Dev
//export const remoteServerUrl: string = "http://38.76.31.91:9090/api/";
//export const reportUrl: string = "http://38.76.31.91:9092/"; //reportstage


//Local Host
// export const remoteServerUrl: string = "http://localhost/Cis.WebApi/api/";
// export const remoteServerUrl: string = "http://localhost:2563/api/";
// export const reportUrl: string = "http://localhost:51560/"; //reportstage




//export const remoteServerUrl: string = "http://infinity2019-001-site4.htempurl.com/api/";
/*Calendar Slot Duration*/
export const calendarSlotDuration: string = "00:10:00";