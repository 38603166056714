<form [formGroup]="visitorForm" novalidate style="text-align: center;">

  <div class="form-group position-relative">
    <input type="IdNumber" placeholder="{{'login.IdNumber'| translate}}" class="form-control" formControlName="IdNumber"
      style="border-radius: 12.5px;" />
    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-at" width="24" height="24"
      viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" fill="none" stroke-linecap="round" stroke-linejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M3 4m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v10a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3z"></path>
      <path d="M9 10m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
      <path d="M15 8l2 0"></path>
      <path d="M15 12l2 0"></path>
      <path d="M7 16l10 0"></path>
    </svg>
  </div>

  <div
    *ngIf="visitorForm.controls['IdNumber'].invalid &&(visitorForm.controls['IdNumber'].dirty || visitorForm.controls['IdNumber'].touched)"
    class="alert alert-danger">
    <div *ngIf="visitorForm.controls['IdNumber'].hasError('pattern')">
      {{ "login.InvalidPattern" | translate }}
    </div>
    <div *ngIf="visitorForm.controls['IdNumber'].hasError('required')">
      {{ "login.reqIdNumber" | translate }}
    </div>
  </div>

  <div class="form-group position-relative">
    <input type="Mobile" placeholder="{{'login.Mobile'| translate}}" class="form-control" formControlName="Mobile"
      style="border-radius: 12.5px;" />
    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-at" width="24" height="24"
      viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" fill="none" stroke-linecap="round" stroke-linejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <rect x="7" y="4" width="10" height="16" rx="1" />
      <line x1="11" y1="5" x2="13" y2="5" />
      <line x1="12" y1="17" x2="12" y2="17.01" />
    </svg>
  </div>

  <div
    *ngIf=" visitorForm.controls['Mobile'].invalid &&(visitorForm.controls['Mobile'].dirty || visitorForm.controls['Mobile'].touched)"
    class="alert alert-danger">
    <div *ngIf="visitorForm.controls['Mobile'].hasError('pattern')">
      {{ "login.InvalidPattern" | translate }}
    </div>
    <div *ngIf="visitorForm.controls['Mobile'].hasError('required')">
      {{ "login.reqMobile" | translate }}
    </div>
  </div>



  <button class="btn" style="background: #f2a000; color: white; text-transform: none;" type="submit"
    [disabled]='!visitorForm.valid' (click)="login()">
    {{ "login.signIn" | translate }}
  </button>

  <div class="my-3">
    <h4 class="d-inline-block" style="color: #ffbe3f;font-weight: bold;">{{ "login.DontHaveAccount" | translate }} </h4>
    <a (click)="SignUp()" class="txtDire  text-white pointer d-inline-block " data-toggle="modal">
     <h4>{{ "login.Register" | translate }}</h4> 
    </a>
    <div>
      <a (click)="ContinueWithoutAnAccount()"  class="txtDire  text-white pointer d-inline-block " data-toggle="modal">
        <h4>{{ "login.ContinueWithoutAnAccount" | translate }}</h4>
        
      </a>
    </div>
  </div>
  <div class="m-3">
    <h2 style="color: white;">{{"subscrible.h2"| translate}} {{"subscrible.br"| translate}} </h2>
    <a class="col-md-6" target="_blank" href="https://play.google.com/store/apps/details?id=com.connclinic.app.flutter">
      <img class=" img-border" src="assets/img/iconfinder_android-app-on-google-play_720092.png" alt="HTML tutorial"
        style="width:170px;height:55px;">
    </a>
    <a class="col-md-6" target="_blank" href="https://apps.apple.com/app/id1592923851">
      <img src="assets/img/appstore.png" alt="HTML tutorial" style="width:170px;height:55px;">
    </a>
    <br />
  </div>
</form>

<!-----------------------Verify Phone Popup--------------------------->
<a class="txtDire  text-white pointer "  id="Verify-Mobile" data-toggle="modal" data-target="#VerifyPhonePopup"></a>

<div id="VerifyPhonePopup" class="modal " role="dialog">
  <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
          <div class="modal-header">
          
              <h4 class="modal-title">{{ 'VerifyMobile' | translate}}</h4>
              <button type="button" class="close" data-dismiss="modal">&times;</button>
          </div>
          <div class="modal-body">
              <div class="container">

                <div class="" >
                  <div id="recaptcha-container"></div>
                </div>

                <div class="">
                    <label for="VerificationCode" class=" control-label">{{ 'VerificationCode' | translate}}</label>
                    <div class="">
                        <input type="email" class="form-control" name="VerificationCode" id="VerificationCode" #VerificationCode  required>
                    </div>
                </div>
              </div>
          </div>
          <div class="modal-footer">
              <button type="button" [disabled]="!VerificationCode" class="btn btn-primary" (click)="verifyLoginCode(VerificationCode.value)">{{ 'Verify' | translate}}</button>
          </div>
      </div>
  </div>
</div>