
export class PermissionKeyEnum {

    AppointmentMaster: string = "AppointmentMaster";
    AppointmentSchedule: string = "AppointmentSchedule";
    CancelAppointment: string = "CancelAppointment";
    SetArrivalTime: string = "SetArrivalTime";
    CloseSlot: string = "CloseSlot";
    OpenSlot: string = "OpenSlot";
    RestoreSlots: string = "RestoreSlots";
    Coding: string = "Coding";
    UpdateServicePrice: string = "UpdateServicePrice";
    EMRMaster: string = "EMRMaster";
    EMR: string = "EMR";
    AdjustInventoryLevel: string = "AdjustInventoryLevel";
    PhysicalCount: string = "PhysicalCount";
    EndorsePhysicalCount: string = "EndorsePhysicalCount";
    TransferTransaction: string = "TransferTransaction";
    OrganizationStructure: string = "OrganizationStructure";
    AccessRightsGroups: string = "AccessRightsGroups";
    AccessRightsUsers: string = "AccessRightsUsers";
    ChargeGroup: string = "ChargeGroup";
    ChargeItem: string = "ChargeItem";
    Claim: string = "Claim";
    AddAttachmentFile: string = "AddAttachmentFile";
    DeleteAttachmentFile: string = "DeleteAttachmentFile";
    CancelChargeGroup: string = "CancelChargeGroup";
    CancelChargeItem: string = "CancelChargeItem";
    CancelClaim: string = "CancelClaim";
    Discount: string = "Discount";
    EditClaimDate: string = "EditClaimDate";
    EditPerformerSelection: string = "EditPerformerSelection";
    PriceOverride: string = "PriceOverride";
    ProcessClaim: string = "ProcessClaim";
    ReleaseClaim: string = "ReleaseClaim";
    UpdateClaim3PPPayer: string = "UpdateClaim3PPPayer";
    CancelPatientTransaction: string = "CancelPatientTransaction";
    ChangePaymentMethod: string = "ChangePaymentMethod";
    ChangeTransactionDate: string = "ChangeTransactionDate";
    ConsumeFromPrepaid: string = "ConsumeFromPrepaid";
    CreditDebit: string = "CreditDebit";
    PaymentIn: string = "PaymentIn";
    PaymentOut: string = "PaymentOut";
    ReleaseTransaction: string = "ReleaseTransaction";
    InternalPurchaseRequest: string = "InternalPurchaseRequest";
    ExternalPurchaseRequest: string = "ExternalPurchaseRequest";
    ReceiveExternalPurchaseRequest: string = "ReceiveExternalPurchaseRequest";
    PatientRegistration: string = "PatientRegistration";
    MRN: string = "MRN";
    AddPatientAttachment: string = "AddPatientAttachment";
    DeletePatientAttachment: string = "DeletePatientAttachment";
    DefinePatientPolicy: string = "DefinePatientPolicy";
    PatientRelatives: string = "PatientRelatives";
    ReportStructure: string = "ReportStructure";
    DeleteReport: string = "DeleteReport";
    ThirdPartyPayersAndPolicies: string = "ThirdPartyPayersAndPolicies";
    LinkPatientToPolicies: string = "LinkPatientToPolicies";
    DefineCoverageDetails: string = "DefineCoverageDetails";
    CancelTransaction: string = "CancelTransaction";
    CancelCharge: string = "CancelCharge";
    ChangeThirdPartyPaymentMethod: string = "ChangeThirdPartyPaymentMethod";
    ChangeThirdPartyTransactionDate: string = "ChangeThirdPartyTransactionDate";
    ThirdPartyConsumeFromPrepaid: string = "ThirdPartyConsumeFromPrepaid";
    ThirdPartyCreditDebit: string = "ThirdPartyCreditDebit";
    ThirdPartyPaymentIn: string = "ThirdPartyPaymentIn";
    ThirdPartyPaymentOut: string = "ThirdPartyPaymentOut";
    ReleaseCharge: string = "ReleaseCharge";
    ReleaseThirdPartyTransaction: string = "ReleaseThirdPartyTransaction";
    GenerateInvoices: string = "GenerateInvoices";
    DeleteInvoices: string = "DeleteInvoices";
    EndorseInvoices: string = "EndorseInvoices";
    IncludeInvoices: string = "IncludeInvoices";
    ExcludeInvoices: string = "ExcludeInvoices";
    CreateMasterPolicy: string = "CreateMasterPolicy";
    Expenses: string = "Expenses";


}